import React, { useEffect, useState } from "react";
import axios from "../utils/axios";
import { Link } from "react-router-dom";

function SelectApply() {
  const [innovations, setInnovations] = useState([]);
  useEffect(() => {
    const setObserver = () => {
      const callback = (entries) => {
        //各 entry（IntersectionObserverEntry オブジェクト）に対して
        entries.forEach((entry) => {
          //監視対象の要素が領域内に入った場合の処理
          if (entry.isIntersecting) {
            entry.target.classList.add("fadeUp");
          } else if (entry.boundingClientRect.y > 0) {
            entry.target.classList.remove("fadeUp");
          }
        });
      };
      const options = {
        threshold: [0.2, 1],
      };
      const observer = new IntersectionObserver(callback, options); //引数にコールバック関数とオプションを指定してオブザーバーを生成
      const targets = document.querySelectorAll(".fadeUpTrigger"); //監視対象の要素（target クラスを指定した要素）を全て取得
      targets.forEach((elem) => {
        observer.observe(elem); //observe() に監視対象の要素を指定
      });
    };
    setObserver();
  }, [innovations]);
  //スクロール位置元に戻す
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getAllInnovations() {
      try {
        const response = await axios.get("/innovation/get_all_innovations");
        //2025年のプロジェクトのみ格納
        const innovationsFromFifth = response.data.innovations.slice(0, 4);
        setInnovations(innovationsFromFifth);
        //setInnovations(response.data.innovations);
      } catch (err) {
        console.log(err);
      }
    }
    getAllInnovations();
  }, []);
  return (
    <div className="MainContents" style={{ backgroundColor: "#f1f1f1" }}>
      <div id="APPLY">
        <div className="PageTitle">
          <p className="f_ubu en">SELECT APPLY</p>
          <h1>応募先を選択してください</h1>
        </div>
        <div className="ContentsContainer">
          {/* 
          <p className="read-text">
            応募先を選択の上、次画面のフォームより資料を添付して応募ください。
            <br />
            ※複数企業への応募は可能です。その際は、それぞれの企業ごとに提出ください。
            <br />
            ※また同一企業への複数案応募も可能です。その際は、データをまとめて応募あるいは複数ファイルに分けての応募もできます。
            <br />
            ※一度提出いただいたうえで追加で応募することも可能です。
          </p>
          */}
          <p className="read-text">
            ご応募ありがとうございました。
            <br />
            審査結果をお待ちください。
            <br />
          </p>
          <div className="scheduleContainer">
            <h2>提出後の流れ</h2>
            <table class="scheduleTable">
              <tbody>
                <tr>
                  <th>本応募締切</th>
                  <td>2月28日（金）23：59</td>
                </tr>
                <tr>
                  <th>書類審査結果</th>
                  <td>3月中旬にメールにて通知予定</td>
                </tr>
                <tr>
                  <th>プレゼン日程調整</th>
                  <td>書類審査結果のメールにて合格者へご案内します。</td>
                </tr>
                <tr>
                  <th>オンラインでのプレゼン実施日</th>
                  <td>
                    3月24（月）- 28（金）の実施を予定しております。 <br />
                    詳しくは説明会および通過連絡メールにてご確認ください。
                  </td>
                </tr>
                <tr>
                  <th>最終発表</th>
                  <td>プレゼン終了後にメールにて通知予定</td>
                </tr>
                <tr>
                  <th>事業者ー採択者間の連携に向けたご案内</th>
                  <td>採択者へ別途ご案内いたします。</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="ApplyContents">
          <ul className="list">
            {innovations.map((innovation) => {
              return (
                <li key={innovation._id} className="articleblock fadeUpTrigger">
                  <Link
                    to={
                      innovation.status === "事前応募" ||
                      innovation.status === "本応募"
                        ? `/apply/${innovation.sequenceNumber}`
                        : ""
                    }
                    className="link"
                  >
                    <div className="logo">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/projects/" +
                          `${innovation.sequenceNumber}/` +
                          "logo.png"
                        }
                        alt=""
                      />
                    </div>
                    <h2 className="title">{innovation.companyName}</h2>
                    <p className="text">{innovation.innovationOverview}</p>

                    <div className="projectStatus">
                      {/*
                      <p
                        className={
                          innovation.status === "事前応募" ? "active" : ""
                        }
                      >
                        {innovation.status === "事前応募" && (
                          <span>&#x2714; </span>
                        )}
                        事前応募受付中
                      </p>
                      <p
                        className={
                          innovation.status === "事前応募受付終了"
                            ? "in-active"
                            : innovation.status === "本応募"
                            ? "in-active"
                            : ""
                        }
                      >
                        {innovation.status === "事前応募受付終了" && (
                          <span>&#x2714; </span>
                        )}
                        {innovation.status === "本応募" && (
                          <span>&#x2714; </span>
                        )}
                        事前応募受付終了
                      </p>
                       */}
                      <p
                        className={
                          innovation.status === "本応募" ? "active" : ""
                        }
                      >
                        {innovation.status === "本応募" && (
                          <span>&#x2714; </span>
                        )}
                        本応募受付中
                      </p>
                      <p
                        className={
                          innovation.status === "本応募受付終了"
                            ? "in-active"
                            : ""
                        }
                      >
                        {innovation.status === "本応募受付終了" && (
                          <span>&#x2714; </span>
                        )}
                        本応募受付終了
                      </p>
                    </div>
                    {/*
                    {innovation.status === "事前応募" ||
                    innovation.status === "本応募" ? (
                      <></>
                    ) : (
                      <p
                        style={{
                          position: "absolute",
                          bottom: "50px",
                          right: "20px",
                          color: "red",
                        }}
                      >
                        {innovation.status}
                      </p>
                    )}
                     */}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SelectApply;
